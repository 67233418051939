////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { MainImage } from '@ibiliaze/reactstrap';
import { FadeIn } from '@ibiliaze/react-intersect';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import About from '../Dashboard/About';
import Copy from '../Dashboard/Copy';
import Reviews from '../Dashboard/Reviews';
import Services from '../Dashboard/Services';
import Album from '../Dashboard/Album';
import Banner from '../Layout/Banner';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import scroll from '../../utils/scroll';
//////////////////////////////////////////////////////////////////////////////////////////

const HomePage = () => (
  <>
    <FadeIn>
      <MainImage
        imgPath={c.mainPic.path}
        carousel={c.mainPic.carousel}
        logoPath={c.mainPic.logo}
        logoHeight={c.mainPic.logoHeight}
        logoLeft={c.mainPic.logoLeft}
        logoBottom={c.mainPic.logoBottom}
        facebookHandle={c.socials.facebookHandle}
        instagramHandle={c.socials.instagram}
        twitterHandle={c.socials.twitterHandle}
        slogan={c.slogan}
        title={c.name.toUpperCase()}
        onButtonClick={_ => scroll(document.getElementById('services-section')?.offsetTop)}
        buttonText={c.mainPic.buttonText}
        infoTop={c.mainPic.infoTop}
        imgBrightness={c.mainPic.imgBrightness}
        imgHeight={c.mainPic.imgHeight}
        imgMobileHeight={c.mainPic.imgMobileHeight}
        titleLetterSpacing={c.mainPic.titleLetterSpacing}
        titleCentre={c.mainPic.titleCentre}
        sloganCentre={c.mainPic.sloganCentre}
        mobileWidth={c.mainPic.mobileWidth}
        boxShadow={c.mainPic.shadow.boxShadow}
        shadowColour={c.mainPic.shadow.shadowColour}
      />
    </FadeIn>
    <About />
    <Banner />
    <Copy />
    <Album />
    <Reviews />
    <Services />
  </>
);

export default HomePage;
