///////////////////////////////////////////////////////////////////////////////////MODULES
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '@ibiliaze/reactstrap';
import { Multirow, Flipper } from '@ibiliaze/react-multirow';
import { TransformYDown, FadeIn } from '@ibiliaze/react-intersect';
import { PageSection } from '@ibiliaze/react-base';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const About = () => {
  // History
  const navigate = useNavigate();

  // JSX
  return (
    <PageSection id='about-section' className='page p-t-xl'>
      <FadeIn>
        <h1 className='t-align-c'>
          About Your <span className='text-gradient'>Best Experience</span>
        </h1>
      </FadeIn>

      <Row className='p-b-m'>
        {c.infos.infos1.map((a, i) => (
          <Col key={i} md={4}>
            <TransformYDown>
              <Multirow img={a.img} alt={a.alt} header={a.header} body={a.body} onClick={_ => a.onClick(navigate)} />
            </TransformYDown>
          </Col>
        ))}
      </Row>

      <div className='p-t-l' />

      <Row>
        <Col md={5}>
          <FadeIn>
            <h2>Vlada Bilenca</h2>

            <p>
              Vlada Bilenca Beauty Clinic in London offers luxurious beauty treatments at reasonable prices. Founded and
              run by Vladislava Bilenca, who after spending many years training, perfecting her trade and then running
              successful beauty salons in Ukraine, decided to bring her concept to London due to high demand from London
              based customers that are looking for more advanced techniques as well as perfection in the results. “In
              Ukraine women always strive for perfection and attention to every small detail. I want my customers to be
              not only happy with the results but fully satisfied, this is why I constantly seek the last techniques and
              technology to offer the best!”. Vlada Bilenca offers treatments such as Lip Enhancement, Anti Wrinkle
              Injections, Semi-Permanent Makeup, Eyebrow and Eyelash Enhancement, PH Formula Facials and more.
            </p>
          </FadeIn>
        </Col>
        <Col md={7}>
          <FadeIn>
            <Flipper title='More about Vlada Bilenca' img='/img/vlada.png' alt='vlada'>
              <div className='p-m'>
                <p>
                  Over 12 years experience in the international beauty industry running successful, leading clinics in
                  Ukraine and United Kingdom. A representative and trainer for the International Permanent Makeup
                  Academy.
                </p>
                <p>Extensive training and qualifications include:</p>
                <p>Master Of Lip Augmentation – GDoctor, Ukraine</p>
                <p>Master Of Botox Injections – GDoctor, Ukraine</p>
                <p>Master Of Full Face Technique – GDoctor, Ukraine</p>
                <p>Master Of Facial Skin Care – VTCT, United Kingdom</p>
                <p>Master Of Facial Electrical Treatments – VTCT, United Kingdom</p>
                <p>Master Of Micropigmentation – VTCT, United Kingdom</p>
              </div>
            </Flipper>
          </FadeIn>
        </Col>
      </Row>
    </PageSection>
  );
};

export default About;
