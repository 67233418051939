////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  CustomButton,
  CustomInput,
  Form,
  FormGroup,
  Label,
  InputGroup,
  CustomInputGroupText,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from '@ibiliaze/reactstrap';
import { roundMs, hmToMs, msToHm } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postProduct, putProduct, deleteProduct } from '../../actions/products';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const ProductForm = ({ toggle, canvas, selectedProduct, settings, postProduct, putProduct, deleteProduct }) => {
  // State
  const [inputs, setInputs] = useState({
    productName: '',
    productGroupName: '',
    description: '',
    imageSrc: '',
    price: 0,
    duration: '',
    courses: 1,
  });

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onDeleteClick = async e => await deleteProduct(selectedProduct?._id);

  // Lifecycle hooks
  useEffect(() => {
    setInputs(c => ({
      ...c,
      productName: selectedProduct?.productName || '',
      productGroupName: selectedProduct?.productGroupName || '',
      description: selectedProduct?.description || '',
      imageSrc: selectedProduct?.imageSrc || '',
      price: selectedProduct?.price || 0,
      duration: selectedProduct?.duration ? msToHm(selectedProduct?.duration) : msToHm(settings?.global?.interval),
      courses: selectedProduct?.courses || 1,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  // onSubmit functions
  const onSubmit = async e => {
    e.preventDefault();
    try {
      const req = !!selectedProduct ? putProduct : postProduct;
      await req({
        ...inputs,
        productId: selectedProduct?._id,
        duration: roundMs(hmToMs(inputs.duration), settings?.global?.roundBooking, settings?.global?.interval),
      });
    } catch (e) {}
  };

  // JSX
  return (
    <Offcanvas fade scrollable isOpen={canvas} toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>Service</OffcanvasHeader>
      <OffcanvasBody>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label>Service Name *</Label>
            <CustomInput
              required
              name='productName'
              placeholder='Service Name'
              value={inputs.productName}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Service Group Name *</Label>
            <CustomInput
              required
              name='productGroupName'
              placeholder='Service Group Name'
              value={inputs.productGroupName}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <CustomInput
              type='textarea'
              name='description'
              placeholder='Description'
              value={inputs.description}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Price *</Label>
            <InputGroup>
              <CustomInputGroupText>{c.currency}</CustomInputGroupText>
              <CustomInput required type='number' name='price' value={inputs.price} onChange={onInputsChange} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>Duration *</Label>
            <CustomInput required type='time' name='duration' value={inputs.duration} onChange={onInputsChange} />
          </FormGroup>
          <FormGroup>
            <Label>Courses</Label>
            <CustomInput type='number' name='courses' value={inputs.courses} onChange={onInputsChange} />
          </FormGroup>
          {!!selectedProduct && (
            <CustomButton onClick={onDeleteClick} color='danger' size={c.b.s}>
              DELETE
            </CustomButton>
          )}{' '}
          <CustomButton type='submit' color='primary' size={c.b.s}>
            SAVE
          </CustomButton>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
};

const mapStateToProps = state => ({ settings: state.settings });

export default connect(mapStateToProps, { postProduct, putProduct, deleteProduct })(ProductForm);
