///////////////////////////////////////////////////////////////////////////////////MODULES
import {
  FormText,
  Label,
  FormGroup,
  Table,
  CloseButton,
  TextButton,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  CustomInput,
  CustomButton,
} from '@ibiliaze/reactstrap';
import { toLocale } from '@ibiliaze/time';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Timeoff = ({
  timeoffs,
  timeoffStart,
  timeoffEnd,
  onTimeoffStartChange,
  onTimeoffEndChange,
  onAddClick,
  onRemoveClick,
}) => (
  <FormGroup>
    <h4 className='t-align-c'>Holidays</h4>
    <Table striped>
      <thead>
        <tr>
          <th>Start</th>
          <th>End</th>
        </tr>
      </thead>
      <tbody>
        {!!timeoffs && timeoffs.length !== 0 ? (
          timeoffs?.map((timeoff, i) => (
            <tr key={i}>
              <td>{toLocale(timeoff.start, process.env.REACT_APP_LOCALE_DATE)}</td>
              <td>{toLocale(timeoff.end, process.env.REACT_APP_LOCALE_DATE)}</td>
              <td>
                <CloseButton onClick={_ => onRemoveClick(i)} />
              </td>
            </tr>
          ))
        ) : (
          <FormText>No time-offs booked</FormText>
        )}
      </tbody>
    </Table>
    <TextButton id='add-timeoff-toggler'>Add time-off</TextButton>
    <UncontrolledPopover trigger='legacy' placement='top' target='add-timeoff-toggler'>
      <PopoverHeader>Add a time-off</PopoverHeader>
      <PopoverBody>
        <FormGroup>
          <Label>Start time</Label>
          <CustomInput type='datetime-local' value={timeoffStart} onChange={onTimeoffStartChange} />
        </FormGroup>

        <FormGroup>
          <Label>End time</Label>
          <CustomInput type='datetime-local' value={timeoffEnd} onChange={onTimeoffEndChange} />
        </FormGroup>

        <CustomButton color='primary' onClick={onAddClick} size={c.b.s}>
          ADD
        </CustomButton>
      </PopoverBody>
    </UncontrolledPopover>
    <br />
    <FormText>Specify the time-off periods. The selected times will be unbookable.</FormText>
  </FormGroup>
);

export default Timeoff;
