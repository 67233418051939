///////////////////////////////////////////////////////////////////////////////////MODULES
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
////////////////////////////////////////////////////////////////////////////////APP ROUTER
import AppRouter from './routers/AppRouter';
/////////////////////////////////////////////////////////////////////////////////////STORE
import configureStore from './store/configureStore';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getUser, getUsers } from './actions/user';
import { getSettings } from './actions/settings';
import { getItems } from './actions/wishlist';
import { getProducts } from './actions/products';
import { getReviews } from './actions/reviews';
import { handleResponse } from './actions/socket';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import socket from './utils/socket';
import c from './utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

document.title = c.name;
document.getElementsByName('description')[0].content = c.slogan;
document.getElementsByName('viewport')[0].content = 'width=device-width, initial-scale=1, maximum-scale=1';

const store = configureStore();
const App = () => {
  useEffect(() => {
    socket.off('messageReceived');
    socket.removeAllListeners('messageReceived');
    socket.on('messageReceived', data => store.dispatch(handleResponse(data)));
    const request = async () => {
      try {
        await store.dispatch(getUser());
        await store.dispatch(getUsers('?select=username avatar'));
        await store.dispatch(getSettings('?type=global'));
        await store.dispatch(getItems());
        await store.dispatch(getProducts());
        await store.dispatch(getReviews());
      } catch (error) {
        console.log(error);
      }
    };

    request();
  }, []);

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

export default App;
