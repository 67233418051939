///////////////////////////////////////////////////////////////////////////////////MODULES
import {
  CustomButton,
  CustomInput,
  FormGroup,
  Label,
  PopoverBody,
  PopoverHeader,
  Table,
  UncontrolledPopover,
} from '@ibiliaze/reactstrap';
import { toLocale } from '@ibiliaze/time';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Account = ({ user, deleteUser, onAvatarChange }) => (
  <>
    <h4 className='t-align-c'>Account</h4>

    <FormGroup>
      <Label>Avatar</Label>
      <CustomInput id='avatar' accept='image/*' type='file' onChange={onAvatarChange} name='avatar' />
    </FormGroup>

    <br />

    <Table borderless style={{ fontSize: 'small' }}>
      <tbody>
        <tr>
          <td>Email</td>
          <td>{user?.user?.email}</td>
        </tr>
        <tr>
          <td>Username</td>
          <td>{user?.user?.username}</td>
        </tr>
        <tr>
          <td>Account created</td>
          <td>{toLocale(user?.user?.createdAt, process.env.REACT_APP_LOCALE_DATE)}</td>
        </tr>
        <tr>
          <td>Last updated</td>
          <td>{toLocale(user?.user?.updatedAt, process.env.REACT_APP_LOCALE_DATE)}</td>
        </tr>
        <tr>
          <td>Account ID</td>
          <td>{user?.user?._id}</td>
        </tr>
      </tbody>
    </Table>

    <CustomButton id='delete-account' onClick={_ => {}} color='danger' size={c.b.s} style={{ float: 'right' }}>
      DELETE ACCOUNT
    </CustomButton>

    <br />

    <UncontrolledPopover placement='top' target='delete-account' trigger='legacy'>
      <PopoverHeader>Continue to delete the account?</PopoverHeader>
      <PopoverBody>
        <CustomButton size={c.b.s}>CANCEL</CustomButton>{' '}
        <CustomButton color='danger' onClick={async _ => await deleteUser()} size={c.b.s}>
          CONTINUE
        </CustomButton>
      </PopoverBody>
    </UncontrolledPopover>
  </>
);

export default Account;
