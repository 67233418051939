///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import {
  CustomInput,
  CustomButton,
  InputGroup,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  FormGroup,
  Label,
  ButtonGroup,
  Row,
  Col,
} from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { deleteBookings } from '../../actions/bookings';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const ManagementHeader = ({ onSearchClick, deleteBookings, inputs, onInputsChange }) => {
  // onClick functions
  const onRemoveAllClick = async _ => await deleteBookings();
  const onRemoveClick = async _ =>
    await deleteBookings(
      `?after=${new Date(inputs.removeEnd).getTime() || 0}&before=${new Date(inputs.removeStart).getTime() || Infinity}`
    );

  // JSX
  return (
    <>
      <UncontrolledPopover placement='bottom' target='filters-toggler' trigger='legacy'>
        <PopoverBody>
          <FormGroup>
            <Label>Payment Status</Label>
            <CustomInput type='select' name='paid' value={inputs.paid} onChange={onInputsChange}>
              <option>All</option>
              <option>Paid</option>
              <option>Unpaid</option>
            </CustomInput>
          </FormGroup>
          <FormGroup>
            <Label>Attendance</Label>
            <CustomInput type='select' name='attended' value={inputs.attended} onChange={onInputsChange}>
              <option>All</option>
              <option>Attended</option>
              <option>Unattended</option>
            </CustomInput>
          </FormGroup>
          <FormGroup>
            <Label>Payment Type</Label>
            <CustomInput type='select' name='paymentType' value={inputs.paymentType} onChange={onInputsChange}>
              <option>All</option>
              {c.paymentTypes.map((pt, i) => (
                <option key={i}>{pt}</option>
              ))}
            </CustomInput>
          </FormGroup>
        </PopoverBody>
      </UncontrolledPopover>

      <Row>
        <Col md={2}>
          <FormGroup>
            <Label>Filters </Label>
            <br />
            <CustomButton id='filters-toggler' style={{ width: '100%' }}>
              FILTERS 🔍
            </CustomButton>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label>Sort</Label>
            <CustomInput type='select' name='sortBy' value={inputs.sortBy} onChange={onInputsChange}>
              <option value='createdAt:asc'>Booking creation - oldest</option>
              <option value='createdAt:desc'>Booking creation - soonest</option>
              <option value='updatedAt:asc'>Updated - oldest</option>
              <option value='updatedAt:desc'>Updated - soonest</option>
              <option value='bookingTime:asc'>Booking time - oldest</option>
              <option value='bookingTime:desc'>Booking time - soonest</option>
            </CustomInput>
          </FormGroup>
        </Col>
        <Col md={7}>
          <FormGroup>
            <Label>Search</Label>
            <InputGroup>
              <CustomInput placeholder='Tommy' name='clientName' value={inputs.clientName} onChange={onInputsChange} />
              <CustomButton color='primary' onClick={onSearchClick}>
                SEARCH
              </CustomButton>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <div style={{ float: 'right' }}>
        <CustomButton color='danger' id='remove-bookings' size={c.b.s}>
          REMOVE BOOKINGS
        </CustomButton>
      </div>
      <UncontrolledPopover placement='top' target='remove-bookings' trigger='legacy'>
        <PopoverHeader>Select Dates</PopoverHeader>
        <PopoverBody>
          <FormGroup>
            <Label>Remove all before:</Label>
            <CustomInput
              name='removeStart'
              value={inputs.removeStart}
              onChange={onInputsChange}
              type='datetime-local'
            />
          </FormGroup>
          <FormGroup>
            <Label>Remove all after:</Label>
            <CustomInput name='removeEnd' value={inputs.removeEnd} onChange={onInputsChange} type='datetime-local' />
          </FormGroup>
          <ButtonGroup>
            <CustomButton id='remove-selected-bookings' color='danger' size={c.b.s}>
              REMOVE BOOKINGS
            </CustomButton>
            <CustomButton id='remove-all-bookings' outline color='danger' size={c.b.s}>
              REMOVE ALL BOOKINGS
            </CustomButton>

            <UncontrolledPopover placement='bottom' target='remove-selected-bookings' trigger='legacy'>
              <PopoverBody>
                <h6>This will delete all the selected bookings. Are you sure you want to continue?</h6>
                <ButtonGroup>
                  <CustomButton color='danger' onClick={onRemoveClick} size={c.b.s}>
                  CONTINUE
                  </CustomButton>
                  <CustomButton size={c.b.s}>Cancel</CustomButton>
                </ButtonGroup>
              </PopoverBody>
            </UncontrolledPopover>

            <UncontrolledPopover placement='bottom' target='remove-all-bookings' trigger='legacy'>
              <PopoverBody>
                <h6>This will delete all bookings. Are you sure you want to continue?</h6>
                <ButtonGroup>
                  <CustomButton color='danger' onClick={onRemoveAllClick} size={c.b.s}>
                    CONTINUE
                  </CustomButton>
                  <CustomButton size={c.b.s}>Cancel</CustomButton>
                </ButtonGroup>
              </PopoverBody>
            </UncontrolledPopover>
          </ButtonGroup>
        </PopoverBody>
      </UncontrolledPopover>
      <br />
      <br />
    </>
  );
};

export default connect(null, { deleteBookings })(ManagementHeader);
