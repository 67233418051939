///////////////////////////////////////////////////////////////////////////////////MODULES
import { PageSection } from '@ibiliaze/react-base';
import { InstagramEmbed } from 'react-social-media-embed';
import { FadeIn } from '@ibiliaze/react-intersect';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Album = () => (
  <PageSection>
    <div className='p-b-xl p-t-xl'>
      <FadeIn>
        <h1 className='t-align-c'>See Our Complete Work</h1>
      </FadeIn>
      <FadeIn>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <InstagramEmbed url={`https://www.instagram.com/${c.socials.instagram}/`} width='100%' />
        </div>
      </FadeIn>
    </div>
  </PageSection>
);

export default Album;
