///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { CustomNav } from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getUser, logout } from '../../actions/user';
import { getSettings } from '../../actions/settings';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import scroll from '../../utils/scroll';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import UserForm from './UserForm';
import Wishlist from '../Wishlist/Wishlist';
import VisitUs from './VisitUs';
//////////////////////////////////////////////////////////////////////////////////////////

const Header = ({ user, wishlist, settings, getUser, getSettings, logout }) => {
  // State
  const [loginModal, setLoginModal] = useState(false);
  const [wishlistModal, setWishlistModal] = useState(false);
  const [visitUsVanvas, setVisitUsCanvas] = useState(false);

  // Toggle functions
  const toggleLoginModal = _ => setLoginModal(!loginModal);
  const toggleWishlistModal = _ => setWishlistModal(!wishlistModal);
  const toggleVisitUscanvas = _ => setVisitUsCanvas(!visitUsVanvas);

  // Navigate
  const navigate = useNavigate();

  // onClick functions
  const onLoginClick = _ => toggleLoginModal();
  const onWishlistClick = _ => toggleWishlistModal();

  // Lifecycle hooks
  useEffect(() => {
    try {
      const req = async _ => {
        // If authentication is succesful redirect to the home page and set the current object states
        if (!!user && user.isAuthenticated) {
          await getUser();
          await getSettings();
          loginModal && setLoginModal(false);
        }
      };

      req();
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isAuthenticated]);

  // JSX
  return (
    <>
      <CustomNav
        dark={c.header.dark}
        blur={c.header.blur}
        logoSrc={c.mainPic.logo}
        logoStyle={c.header.styles}
        navbarBrand={c.name}
        navbarBrandHref='#'
        navbarBrandOnClick={_ => navigate('/')}
        fadeOnPaths={c.header.fadeOnPaths}
        navlinks={[
          {
            title: 'About',
            href: '#',
            onClick: _ => {
              navigate('/');
              setTimeout(() => {
                scroll(document.getElementById('about-section')?.offsetTop);
              }, 1);
            },
            hide: false,
          },
          {
            title: 'Services',
            href: '#',
            onClick: _ => {
              navigate('/');
              setTimeout(() => {
                scroll(document.getElementById('services-section')?.offsetTop);
              }, 1);
            },
            hide: false,
          },
          {
            title: 'Bookings',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/bookings');
            },
            hide: false,
          },
          {
            title: 'Insights',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/insights');
            },
            hide: !user.isAuthenticated,
          },
          {
            title: 'Contact',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, document.body.scrollHeight);
            },
            hide: false,
          },
        ]}
        navlinksRight={[
          // auth links
          {
            title: 'Settings ⚙',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/settings');
            },
            hide: !user.isAuthenticated,
          },
          {
            title: user && user.user ? `${user.user.username}@${c.name}` : '',
            text: true,
            hide: !user.isAuthenticated,
            // badge: user && user.user && user.user?.admin ? true : false,
            // badgeColour: 'primary',
            onClick: _ => {},
          },
          {
            title: 'Logout',
            href: '#',
            onClick: async _ => await logout(),
            hide: !user.isAuthenticated,
          },

          // guest links
          {
            title: `Cart 🛒 ${wishlist.items.length}`,
            href: '#',
            onClick: _ => onWishlistClick(),
            hide: user.isAuthenticated,
            badge: true,
            badgeColour: 'primary',
          },
          {
            title: 'Visit us',
            href: '#',
            onClick: toggleVisitUscanvas,
            hide: user.isAuthenticated,
          },
          {
            title: 'Login',
            href: '#',
            onClick: onLoginClick,
            hide: user.isAuthenticated,
          },
        ]}
      />
      <VisitUs isOpen={visitUsVanvas} toggle={toggleVisitUscanvas} workingHours={settings?.global?.workingHours} />
      <UserForm modal={loginModal} toggle={toggleLoginModal} />
      <Wishlist modal={wishlistModal} toggle={toggleWishlistModal} />
    </>
  );
};

const mapStateToProps = state => ({ wishlist: state.wishlist, user: state.user, settings: state.settings });

export default connect(mapStateToProps, { getUser, getSettings, logout })(Header);
