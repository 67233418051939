import scroll from './scroll';
export const env = process.env;

const c = {
  // GENERAL INFO
  name: 'Vlada Bilenca',
  nameJs: 'vladabilenca',
  slogan: 'Beauty. Standards.',
  phone1: '07503 296489',
  email1: 'vladabilenca@gmail.com',
  hqAddr: '4 Dereham Road, IG11 9HA, Barking, Essex',
  chatPerson: 'Vlada',
  chatMessage: `Hello there! \n \nHow can we help?`,
  chatStatus: 'Typically replies within a few minutes',
  cosmetologistType: 'Practitioner',
  paymentTypes: ['card', 'cash'],
  currency: '£',
  darkMode: true,

  // MAIN PIC
  mainPic: {
    path: '/img/home.jpg',
    carousel: [
      {
        src: '/img/main/main1.jpg',
        altText: 'Natural Lip Augmentation',
        // caption: 'Slide 1',
        text: 'hello',
        key: 1,
      },
      {
        src: '/img/main/main2.jpg',
        altText: 'Stunning Lash Fits',
        // caption: 'Slide 2',
        text: 'hello',
        key: 2,
      },
      {
        src: '/img/main/main3.jpg',
        altText: 'Semi-Permanent Makeup',
        // caption: 'Slide 3',
        text: 'hello',
        key: 3,
      },
    ],
    // logo: '/img/logo.png',
    buttonText: 'Book an Appointment',
    logoHeight: '10%',
    logoLeft: '20px',
    logoBottom: '20px',
    infoTop: '60%',
    imgBrightness: '.7',
    imgHeight: '650px',
    imgMobileHeight: '300px',
    titleLetterSpacing: '0.3rem',
    titleCentre: true,
    sloganCentre: true,
    mobileWidth: 700,
    shadow: {
      boxShadow: '0px 0px 200px 0px ',
      shadowColour: 'rgb(0 55 146) ',
    },
  },

  // SECONDARY PIC
  secondaryPic: {
    path: '/img/bikini.jpg',
    imgHeight: '500px',
    imgMobileHeight: '500px',
    imgBrightness: '0.4',
    shadow: {
      boxShadow: '0px 0px 200px 30px ',
      shadowColour: 'rgb(255 220 172) ',
    },
    slogan:
      "Aesthetics and beauty play a crucial role in enhancing one's confidence and self-image. At our beauty clinic, we believe that everyone deserves to feel their best, and that's why we strive to provide the highest quality aesthetic treatments.",
  },

  // SOCIALS
  socials: {
    instagram: 'vladabilenca',
    // facebook: 'vladabilenca',
    // twitter: 'vladabilenca',
  },

  // CALLBACK
  callback: {
    allowCallback: true,
    callbackPeriods: ['9am-12pm', '12pm-3pm', '3pm-6pm', '9pm-12am'],
  },

  // HEADER
  header: {
    blur: '1px',
    dark: true,
    styles: { height: '40px' },
    fadeOnPaths: ['/'],
  },

  // CAROUSEL
  carousel: {
    dark: true,
    fade: false,
    indicators: false,
  },

  // TYPE EFFECTS
  textType: [
    'Lip Augmentation',
    'Anti Wrinkle Injections',
    'Semi-Permanent Makeup',
    'Eyebrows & Eyelashes',
    'PH Formula Facials',
  ],

  // INFOS
  infos: {
    infos1: [
      {
        header: 'Lip Augmentation',
        body: 'Hyaluronic Dermal Filler injections which enhance your natural lip shape and size, resulting in a beautifully balanced face and smile.',
        img: '/img/info/lip-augmentation.jpg',
        alt: 'infos1-1',
        onClick: navigate => scroll(document.getElementById('lip-augmentation-section')?.offsetTop),
      },
      {
        header: 'Anti Wrinkle Injections',
        body: 'Anti-Wrinkle injections soften lines and wrinkles in between the eyebrows, around the eyes and across the forehead.',
        img: '/img/info/anti-wrinkle-injections.jpg',
        alt: 'infos1-2',
        onClick: navigate => scroll(document.getElementById('anti-wrinkle-injections-section')?.offsetTop),
      },
      {
        header: 'Semi-Permanent Makeup',
        body: 'Semi-permanent make-up is a complexion treatment to enhance eyes, brows and lips to add definition to the face. This technique involves injecting pigment through a fine vibrating needle into the most superficial layers of the skin.',
        img: '/img/info/semi-permanent-makeup.jpg',
        alt: 'infos1-3',
        onClick: navigate => scroll(document.getElementById('services-section')?.offsetTop),
      },
    ],
    infos2: [
      {
        header: 'Eyebrows & Eyelashes',
        body: 'Lash Lift treatment works by lifting each individual lash to its full potential, then tinting them for thicker, darker, and more dramatic looking lashes. Eyebrow tinting is the process of applying semi-permanent dye to enhance, shape, and define your brows.',
        img: '/img/info/lip-augmentation.jpg',
        alt: 'infos1-1',
        onClick: navigate => window.open('/auth'),
      },
      {
        header: 'PH Formula Facials',
        body: 'Skin treatments targeted to the needs of your skin type – whether that is anti-ageing, hyperpigmentation, chronic redness, acne, wrinkles, or even if you are looking for an instant boost.',
        img: '/img/info/anti-wrinkle-injections.jpg',
        alt: 'infos1-2',
        onClick: navigate => window.open('/auth'),
      },
    ],
  },

  // REACTSTRAP BUTTONS
  b: {
    s: 'sm',
  },

  // REACTSTRAP PAGING
  p: {
    s: 'md',
  },
};

export default c;
