///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { msToHm } from '@ibiliaze/time';
import { CustomCard, MiniNav, Row, Col, Fade, CustomButton } from '@ibiliaze/reactstrap';
import { FadeIn, TransformYDown } from '@ibiliaze/react-intersect';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postItem, deleteItem } from '../../actions/wishlist';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import scroll from '../../utils/scroll';
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Wishlist from '../Wishlist/Wishlist';
import ProductForm from './ProductForm';
//////////////////////////////////////////////////////////////////////////////////////////

const Services = ({ wishlist, products, isAuthenticated, postItem, deleteItem }) => {
  // State
  const [wishlistModal, setWishlistModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState({});

  // Toggle functions
  const toggleWishlistModal = _ => setWishlistModal(!wishlistModal);
  const toggleProductModal = _ => setProductModal(!productModal);

  // onClick functions
  const onAddProductClick = _ => {
    setSelectedProduct(null);
    toggleProductModal();
  };
  const onProductClick = product => {
    setSelectedProduct(product);
    toggleProductModal();
  };
  const onAddClick = async product => {
    await postItem({
      ...product,
      quantity: 1,
      duration: product.duration,
    });
  };

  const onRemoveClick = async product => await deleteItem(product._id);

  // Lifecycle hooks
  useEffect(() => {
    try {
      const outputObject = {};

      products.forEach(product => {
        const productGroupName = product['productGroupName'];

        if (outputObject[productGroupName]) {
          outputObject[productGroupName].push(product);
        } else {
          outputObject[productGroupName] = [product];
        }
      });

      setFilteredProducts(outputObject);
    } catch (e) {
      setFilteredProducts({});
    }
  }, [products]);

  // JSX
  return (
    <>
      <div id='services-section' className='p-t-xl p-b-m'>
        <FadeIn>
          <h1 className='t-align-c'>Services</h1>
        </FadeIn>

        {isAuthenticated && (
          <div className='t-align-c'>
            <CustomButton color='primary' size={c.b.s} onClick={onAddProductClick}>
              ADD A SERVICE
            </CustomButton>
            <br />
            <br />
          </div>
        )}

        {!!products && (
          <>
            <MiniNav
              blur={c.header.blur}
              navlinks={Object.keys(filteredProducts)?.map((pg, i) => ({
                title: pg,
                href: '#',
                onClick: _ => {
                  scroll(document.getElementById(`products-${pg}-section`)?.offsetTop);
                },
              }))}
            />
            <section className='page'>
              {Object.keys(filteredProducts)?.map((pg, i) => (
                <section key={i} className='p-t-xxl' id={`products-${pg}-section`}>
                  <TransformYDown>
                    <br />
                    <h4 className='t-align-c'>{pg}</h4>
                    <hr />
                    <Row>
                      {filteredProducts[pg].map((p, i) => (
                        <Col md={3} key={i}>
                          <CustomCard
                            multiclick={isAuthenticated ? false : true}
                            title={p.productName}
                            titleTag='h6'
                            subtitle={`${c.currency + p?.price}`}
                            subtitleTag='h6'
                            text={`${p?.courses} courses`}
                            hoverTitle={isAuthenticated ? 'Edit service' : 'Add to Wishlist'}
                            clickedTitle={'Added - ' + p?.productName}
                            onCardClickHandler={isAuthenticated ? _ => onProductClick(p) : _ => onAddClick(p)}
                            onCardSecondClickHandler={_ => onRemoveClick(p)}
                            args={p}
                          />
                        </Col>
                      ))}
                    </Row>
                  </TransformYDown>
                </section>
              ))}
              <Wishlist modal={wishlistModal} toggle={toggleWishlistModal} />
              <ProductForm canvas={productModal} toggle={toggleProductModal} selectedProduct={selectedProduct} />
            </section>
          </>
        )}
      </div>

      {wishlist?.items?.length !== 0 && (
        <Fade className='mt-3 wishlist-toggler t-align-c'>
          <div>
            {c.currency}
            {wishlist?.total}
          </div>
          <div>
            {wishlist?.items?.length} services, {msToHm(wishlist?.duration)}
          </div>
          <CustomButton color='primary' size={c.b.s} onClick={toggleWishlistModal}>
            CONTINUE
          </CustomButton>
        </Fade>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  wishlist: state.wishlist,
  products: state.products,
  isAuthenticated: state.user.isAuthenticated,
});

export default connect(mapStateToProps, { postItem, deleteItem })(Services);
