///////////////////////////////////////////////////////////////////////////////////MODULES
import { Col, Row, CustomButton } from '@ibiliaze/reactstrap';
import { FadeIn } from '@ibiliaze/react-intersect';
import { Flipper } from '@ibiliaze/react-multirow';
import CompareImage from 'react-compare-image';
import { TextType } from '@ibiliaze/react-text-type';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import scroll from '../../utils/scroll';
//////////////////////////////////////////////////////////////////////////////////////////

const Copy = () => {
  // JSX
  return (
    <section className='page p-t-xl p-b-xl'>
      <FadeIn>
        <h2 className='t-align-c'>
          <TextType strings={c.textType} />
        </h2>
      </FadeIn>

      <Row>
        <Col md={6}>
          <FadeIn>
            <Flipper title={c.infos.infos2[0].header} img={c.infos.infos2[0].img} alt='vlada'>
              <div className='p-m'>
                <p>{c.infos.infos2[0].body}</p>
                <CustomButton
                  color='primary'
                  black
                  onClick={_ => scroll(document.getElementById('services-section')?.offsetTop)}
                >
                  BOOK NOW
                </CustomButton>
              </div>
            </Flipper>
          </FadeIn>
        </Col>
        <Col md={6}>
          <FadeIn>
            <h2>Eyelash & Eyebrow Enhancement</h2>

            <p>
              One way to think of a lash lift is like a perm for your eyelashes. Unlike a lash extension, a lash lift
              simply alters the shape and color of your natural lashes. In most people, lashes grow out, rather than up.
              However, upward growing lashes will give a more cosmetically pleasing appearance. The treatment involves
              boosting and lifting each individual lash, before tinting them for thicker, darker, longer looking lashes.
              Eyebrow tinting is the process of applying semi-permanent dye to enhance, shape, and define your brows.
              Tinting involves manipulating the brow area by darkening the fine hairs that surround the perimeter of the
              brow to increase the width. It also involves the darkening of the fine hairs in the inner core of the brow
              that are in less dense areas. This creates a longer brow line (brow tail) or fuller front and a more ideal
              shape.
            </p>
          </FadeIn>
        </Col>
        <div className='p-b-xl' />
        <Col md={6}>
          <FadeIn>
            <h2>PH Formula Facials</h2>

            <p>
              PhFormula is the first pharma-cosmeceutical skin resurfacing line, which is the result of an innovative
              alliance between cosmeceuticals and medical prescriptions. The action of controlled chemical skin
              resurfacing is totally different than conventional peelings. Peels mainly cause skin exfoliation, whereas
              controlled resurfacing actively provokes an accelerated form of cell regeneration in the different layers
              of the skin, whilst trauma and superficial irritation is reduced. Developed as a dermatological skin
              resurfacing system, phformula treats skin disorders like Premature Ageing, Hyper-pigmentation, Acne and
              Chronic Redness.
            </p>
          </FadeIn>
        </Col>
        <Col md={6}>
          <FadeIn>
            <Flipper title={c.infos.infos2[1].header} img={c.infos.infos2[1].img} alt='vlada'>
              <div className='p-m'>
                <p>{c.infos.infos2[1].body}</p>
                <CustomButton
                  color='primary'
                  black
                  onClick={_ => scroll(document.getElementById('services-section')?.offsetTop)}
                >
                  BOOK NOW
                </CustomButton>
              </div>
            </Flipper>
          </FadeIn>
        </Col>
      </Row>

      <div className='p-b-xl' />

      <section id='lip-augmentation-section'>
        <Row>
          <Col md={4}>
            <FadeIn>
              <CompareImage leftImage='/img/comparisons/Lips-Before.png' rightImage='/img/comparisons/Lips-After.png' />
            </FadeIn>
          </Col>
          <Col md={8}>
            <FadeIn>
              <h2>Lip Augmentation</h2>
              <p>
                Lip augmentation is achieved by injecting Hyaluronic Dermal Filler along the lip border and also into
                the lip until the the patient’s desired outcome is achieved. This adds shape and volume to the lip,
                resulting in a beautifully balanced face and smile. Vlada Bilenca lip filler procedures use the optimal
                brand of dermal fillers with state of the art treatments, which result in perfectly natural looking
                lips.
              </p>
              <CustomButton
                color='primary'
                black
                onClick={_ => scroll(document.getElementById('services-section')?.offsetTop)}
              >
                BOOK NOW
              </CustomButton>
            </FadeIn>
          </Col>
        </Row>
      </section>

      <div className='p-b-xl' />

      <section id='anti-wrinkle-injections-section'>
        <Row>
          <Col md={4}>
            <FadeIn>
              <CompareImage
                leftImage='/img/comparisons/Botox-Before.png'
                rightImage='/img/comparisons/Botox-After.png'
              />
            </FadeIn>
          </Col>
          <Col md={8}>
            <FadeIn>
              <h2>Anti Wrinkle Injections</h2>
              <p>
                Anti Wrinkle injections provide a safe and effective treatment for men and women that does not require
                surgery and is used to improve the appearance of lines and wrinkles. Anti Wrinkle injections in London
                at Valda Bilenca are quick, safe and simple. Anti wrinkle injections cause wrinkles to fade and lines to
                become smoothed resulting in a younger you with head turning results. At our London clinic, injections
                can be used to treat the forehead, frown area, crows area (eyes), cheeks and chin.
              </p>
              <CustomButton
                color='primary'
                black
                onClick={_ => scroll(document.getElementById('services-section')?.offsetTop)}
              >
                BOOK NOW
              </CustomButton>
            </FadeIn>
          </Col>
        </Row>
      </section>
    </section>
  );
};

export default Copy;
